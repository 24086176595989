import React, { useReducer, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import Button from 'components/Button'
import Modal from 'components/Modal'
import useProduct from 'hooks/useProduct'
import useProductContext from 'hooks/useProductContext'
import FocusArea from 'components/FocusArea'
import SwipeableViews from 'react-swipeable-views'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'
import Container from 'components/Container'

const Col = styled.div`
  align-self: stretch;
  width: 50%;
  position: relative;
  border-left: 1px solid white;
  ${mediaQuery()({ tablet: 'border-left: none; width: 100%; > div > div > div { padding: 0 !important; }' })}
`

const Description = styled.div`
  padding: 24px 0;
`
const Next = styled.div`
  border-top: 1px solid white;
  border-right: 1px solid white;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  :hover {
    border-color: ${({ theme }) => theme.colors.base.orange}
  }
  ${mediaQuery()({ mobile: 'right: 0;' })}
`
const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  padding-left: 138px;
  ${mediaQuery()({ tablet: 'padding-left: 0;' })}
`
const Dot = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  margin: 9px;
  padding: 9px;
  ${({ active }) => (active ? 'background-color: white;' : '')}
`

export default function storyblokHighlightCarousel ({ data, ...props }) {
  const [open, toggle] = useReducer(state => !state, false)
  const product = useProduct(data.product[0])
  const highlights = useMemo(
    () => product?.component?.highlights,
    [product]
  )

  const { selected, select } = useProductContext(product, 'highlight')

  const highlight = selected
    ? highlights?.findIndex(hl => hl.highlight[0].id === selected)
    : 0

  const handleChange = useCallback(
    (idx) => {
      select(highlights[idx].highlight[0].id)
    },
    [highlights]
  )
  const focusProps = highlights?.[highlight]
    ? (highlights[highlight].bottom && highlights[highlight].left) ? {
      top: highlights[highlight].top, left: highlights[highlight].left, right: 100 - Number(highlights[highlight].right), bottom: 100 - Number(highlights[highlight].bottom)
    }
      : {
        top: Number(highlights[highlight].top) - 10, left: Number(highlights[highlight].left) - 10, right: 90 - Number(highlights[highlight].left), bottom: 90 - Number(highlights[highlight].top)
      }
    : {}

  return (
    <Container
      bg="gray"
      max="overflow: hidden; display: flex; align-items: flex-start;"
      tablet="flex-direction: column; padding-bottom: 100px;"
      {...props}
    >
      <Modal
        product={product}
        type="highlight"
        open={open}
        onRequestClose={toggle}
      />
      <Image
        flex
        row
        max="width: 50%;"
        tablet="width: 100%;"
        src={product?.component?.image}
        alt={product?.component?.imageAlt}
        title={product?.component?.imageTitle}
        description={product?.component?.imageDescription}
      >
        <FocusArea
          {...focusProps}
        />
      </Image>
      <Col>
        <SwipeableViews
          index={highlight}
          onChangeIndex={handleChange}
          slideStyle={{
            display: 'flex', flexDirection: 'column', padding: '10px 0 10px 138px', overflow: 'hidden'
          }}
        >
          {highlights?.map(hl =>
            hl.highlight.map(({ component }) => (
              <>
                <h5>{component?.title}</h5>
                <Description>
                  {component?.description?.childMarkdownRemark?.htmlAst && (
                    <RemarkRenderer
                      flexFill
                      ast={component?.description?.childMarkdownRemark?.htmlAst}
                      style={{ padding: '5px 0' }}
                    />
                  )}
                </Description>
                <Image
                  flex
                  row
                  src={component?.image}
                  style={{
                    maxHeight: '80vh',
                    overflow: 'hidden'
                  }}
                  alt={component?.imageAlt}
                  title={component?.imageTitle}
                  description={component?.imageDescription}
                />
              </>
            ))
          )}
        </SwipeableViews>
        <Next
          onClick={() => handleChange((highlight + 1) % highlights.length)}
        />
        <Pagination>
          {highlights?.map((hl, idx) => (
            <Dot active={idx === highlight} onClick={() => handleChange(idx)} />
          ))}
        </Pagination>
      </Col>
    </Container>
  )
}
