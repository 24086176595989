import React, { useContext, useState, useCallback } from 'react'
import { Formik } from 'formik'
import { ModalContext } from 'components/Modal'
import NavContext from 'components/NavProvider'
import StoryblokComponent from 'components/StoryblokComponent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/Button'
import Container from 'components/Container'
import RemarkRenderer from 'components/RemarkRenderer'
import useRecaptcha from '../../hooks/useRecaptcha'

const sanitizeLang = lang =>
  ({
    'fr-ch': 'fr',
    default: 'en',
    'sv-sv': 'sv',
    'de-de': 'de',
    'de-at': 'de',
    'de-ch': 'de',
    no: 'no',
    fi: 'fi',
    da: 'da',
    'sl-si': 'sl',
  }[lang])

const sanitizeCountry = lang =>
  ({
    'fr-ch': 'Switzerland',
    'sv-sv': 'Sweden',
    'de-de': 'Germany',
    'de-at': 'Austria',
    'de-ch': 'Switzerland',
    no: 'Norway',
    fi: 'Finland',
    da: 'Denmark',
    'sl-si': 'Slovenia',
  }[lang])

export default function StoryblokForm({ data, inheritBg, ...props }) {
  const { lang } = useContext(NavContext)
  const { dealer, formData, placesResult = null, onRequestClose, content } =
    useContext(ModalContext) || {}
  const [dialog, setDialog] = useState(false)
  const handleClose = useCallback(() => {
    setDialog(false)
    onRequestClose()
  })

  const { recaptcha, RecaptchaContainer } = useRecaptcha()

  const submitForm = useCallback(
    async (values, { setSubmitting }) => {
      if (!recaptcha) return console.debug(`Verification not ready yet!`)

      const isNotARobot = await recaptcha.verify()

      // TODO: should we log anything to the database in this case?
      if (!isNotARobot) return console.debug(`DEBUG failed auth`)

      dataLayer.push({
        type: content?.name,
        postCode: values?.postCode,
        dealer: dealer?.id,
        event: 'form-submit',
        brochureName: values?.brochureRequest,
        brochureType: values?.brochureDeliveryType,
        pageURL: window.location.href,
      })

      fetch('/crm-functions/submitTicket', {
        method: 'POST',
        credentials: 'omit',
        body: JSON.stringify({
          type: data.type,
          autoAssign: data.autoAssign,
          assignSalesRep: data.assignSalesRep,
          ...(data.assignDealer && { assignStore: dealer?.id }),
          placesResult,
          language: sanitizeLang(lang),
          data: {
            country: sanitizeCountry(lang) || '',
            ...values,
            ...(!data.assignDealer &&
              dealer && {
                internalComment: `Selected dealer: ${dealer?.dealerNumber} ${dealer?.name}`,
              }),
          },
        }),
        headers: {
          'content-type': 'application/json',
        },
      }).then(() => {
        console.log('submit')
        setDialog(true)
        setSubmitting(false)
      })
    },
    [recaptcha, RecaptchaContainer]
  )

  return (
    <>
      <Container bg={data.color || inheritBg} {...props}>
        <Formik initialValues={formData} onSubmit={submitForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {data.body.map(inp => (
                <StoryblokComponent
                  key={inp.id}
                  component={inp}
                  inheritBg={data.color || inheritBg}
                />
              ))}
            </form>
          )}
        </Formik>

        {RecaptchaContainer || null}

        <Dialog onClose={handleClose} open={dialog}>
          <DialogTitle onClose={handleClose}>{data.successTitle}</DialogTitle>
          <DialogContent>
            <RemarkRenderer
              ast={data.successMessage?.childMarkdownRemark?.htmlAst}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{data.successButton}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}
