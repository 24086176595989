import React, { useEffect, useState } from 'react'
import useFirebase from 'hooks/useFirebase'

export default function useRecaptcha() {
  const { firebase } = useFirebase()
  const [recaptcha, setRecaptcha] = useState(null)
  const RecaptchaContainer = <div id="recaptcha-container" />

  useEffect(() => {
    if (!firebase?.firebase_?.auth?.RecaptchaVerifier) return

    const verifier = new firebase.firebase_.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'normal',
        // size: 'invisible', // this seems to not flag programmatic clicks
      }
    )
    setRecaptcha(verifier)

    return () => verifier.clear()
  }, [firebase])

  return { recaptcha, RecaptchaContainer }
}
