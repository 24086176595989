import styled from 'styled-components'

export default styled.div`
  padding: 16px;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  opacity: 0;
  z-index: 1;
`
