import React from 'react'
import Check from '@material-ui/icons/Check'
import styled from 'styled-components'
import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import { color } from 'utils/style'

const CheckIcon = styled(Check)`
  && {
    color: ${color('c', 'contrast')};
    border: 1px solid ${color('c', 'contrast')};
    background-color: ${color('c', 'color')};
    font-size: 1.5em;
  }
`

const UncheckIcon = styled(Check)`
  && {
    color: ${color('c', 'color')};
    border: 1px solid ${color('c', 'contrast')};
    background-color: white;
    font-size: 1.5em;
  }
`

const Label = styled(FormControlLabel)`
 > span {
   font-size: inherit;
 }
`

export default function Checkbox ({
  label, color: uncheckedColor = 'white', checkedColor = uncheckedColor, ...props
}) {
  return <Label control={<MuiCheckbox icon={<UncheckIcon c={uncheckedColor} />} checkedIcon={<CheckIcon c={checkedColor} />} {...props} />} label={label} />
}
