import { useEffect, useState, useRef } from 'react'
import { equals } from 'ramda'

export default (query) => {
  const [state, setState] = useState(null)
  const ref = useRef(null)

  useEffect(() => {
    if (query && !equals(query, ref.current)) {
      ref.current = query
      window.storyblok.getAll({ ...query }, setState)
    }
  }, [query])

  return state
}
